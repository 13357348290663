<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane v-for="item in moduleList" :key="item.id" :tab="item.name">
      <!-- 语音线路 -->
      <div class="privacy-box" v-if="activeKey == '1'">
        <div class="flex">
          <div style="margin-left: 10px">接口类型：</div>
          <el-select
            v-model="interfaceIdType"
            placeholder="请选择查询的接口类型"
            style="width: 20%"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in interface_id"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <div style="margin-left: 10px">计费类型：</div>
          <el-select
            v-model="searchForm.type"
            clearable
            placeholder="请选择"
            style="width: 20%"
          >
            <el-option
              v-for="item in billingType"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div style="margin-left: 10px">服务商：</div>
          <el-select
            placeholder="请选择查询的服务商"
            v-model="searchForm.amountId"
            size="small"
            style="width: 20%"
            @change="setDataList"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in amount_id"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>

        <div class="add-box">
          <el-button
            size="small"
            plain
            type="success"
            round
            @click="queryprivacy"
          >
            查询
            <!-- <icon-font type="icon-web-icon-" class="img-icon"/> -->
          </el-button>
          <el-button
            size="small"
            plain
            type="success"
            round
            @click="addprivacy"
          >
            增加
            <!-- <icon-font type="icon-web-icon-" class="img-icon"/> -->
          </el-button>
        </div>
        <div class="privacy-most">
          <div class="pItem-box" v-for="(pItem, p) in privacylist" :key="p">
            <el-select
              v-model="pItem.interfaceId"
              placeholder="请选择"
              filterable
              :disabled="pItem.id == null || pItem.id == '' ? false : true"
            >
              <el-option
                v-for="item in interfaceList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <div class="out-left input-far-width">
              <div class="input-name-price-width">
                <el-select
                  v-model="pItem.type"
                  placeholder="请选择"
                  :disabled="pItem.id == null || pItem.id == '' ? false : true"
                >
                  <el-option
                    v-for="item in billingType"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="input-box">
                <el-input
                  class="input-price-width"
                  v-model="pItem.price"
                  placeholder="请输入价格"
                  ><template #append>￥</template></el-input
                >
              </div>
            </div>
            <div class="out-left">
              <el-tooltip content="开启/禁用" placement="top" effect="light">
                <el-switch
                  style="display: block"
                  v-model="pItem.status"
                  :active-value="0"
                  :inactive-value="1"
                  active-color="#637DFF"
                  inactive-color="#ff6e3e"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div class="out-left">
              <el-button
                size="small"
                style="color: red"
                type="text"
                round
                @click="deleteOne(p, pItem)"
              >
                删除
                <!-- <icon-font type="icon-web-icon-" class="img-icon"/> -->
              </el-button>
            </div>
          </div>
        </div>

        <div class="bottom-box1">
          <!-- <el-button size="small" round @click="cancel">
                        取消
                    </el-button> -->
          <pagi-nation
            :pageSizesType="[10, 20, 50]"
            :style="{ marginTop: '20px' }"
            :total="groupTotal"
            :limit="groupPageSize"
            :page="groupPage"
            @handleChange="handleGroupChange"
          ></pagi-nation>
          <el-button size="small" type="primary" plain round @click="save">
            保存
            <!-- <icon-font type="icon-web-icon-" class="img-icon"/> -->
          </el-button>
        </div>
      </div>
      <!-- 语音质检 -->
      <div v-else>
        <div class="privacy-most">
          <div style="margin-bottom: 10px" v-if="activeKey == '3'">
            <el-radio-group v-model="telTypeRadio">
              <el-radio label="1">棱睿1</el-radio>
              <el-radio label="2">电话邦</el-radio>
              <el-radio label="3">棱睿2</el-radio>
              <el-radio label="5">浙江风临</el-radio>
              <el-radio label="6">飞亚</el-radio>
            </el-radio-group>
          </div>

          <div class="pItem-box">
            <div class="input-box-other">
              <el-tooltip content="用户使用价格" placement="top" effect="light">
                <el-input
                  v-model="otherList.usePrice"
                  placeholder="请输入用户使用价格"
                  ><template #append>￥</template></el-input
                >
              </el-tooltip>
            </div>
            <div class="input-box-other">
              <el-tooltip content="接口能力价格" placement="top" effect="light">
                <el-input
                  v-model="otherList.apiPrice"
                  placeholder="请输入接口能力价格"
                  ><template #append>￥</template></el-input
                >
              </el-tooltip>
            </div>
            <div v-if="activeKey == '2'" class="input-box-other">
              <el-tooltip
                content="一句话识别价格"
                placement="top"
                effect="light"
              >
                <el-input
                  v-model="otherList.asrPrice"
                  placeholder="一句话识别价格"
                  ><template #append>￥</template></el-input
                >
              </el-tooltip>
            </div>
            <div v-if="activeKey == '2'" class="out-left">
              <el-tooltip
                content="一句话识别价格状态"
                placement="top"
                effect="light"
              >
                <el-switch
                  style="display: block"
                  v-model="otherList.asrStatus"
                  :active-value="0"
                  :inactive-value="1"
                  active-color="#637DFF"
                  inactive-color="#ff6e3e"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div class="out-left">
              <el-tooltip content="开通状态" placement="top" effect="light">
                <el-switch
                  style="display: block"
                  v-model="otherList.useStatus"
                  :active-value="0"
                  :inactive-value="1"
                  active-color="#637DFF"
                  inactive-color="#ff6e3e"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div class="out-left">
              <el-tooltip content="能力状态" placement="top" effect="light">
                <el-switch
                  style="display: block"
                  v-model="otherList.apiStatus"
                  :active-value="0"
                  :inactive-value="1"
                  active-color="#637DFF"
                  inactive-color="#ff6e3e"
                >
                </el-switch>
              </el-tooltip>
            </div>
          </div>
        </div>

        <div class="bottom-box">
          <!-- <el-button size="small" round @click="cancelOther">
                        取消
                    </el-button> -->
          <el-button size="small" round @click="saveOther">
            保存
            <!-- <icon-font type="icon-web-icon-" class="img-icon"/> -->
          </el-button>
        </div>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import app from "@/api/web/app";
import userModule from "@/api/web/userModule";
import userPrice from "@/api/open/userPrice/userPrice";
import interfaces from "@/api/open/interface/interface";
import blackUserPrice from "@/api/open/blackUserPrice/blackUserPrice";
import voiceUserPrices from "@/api/open/voiceUserPrice/voiceUserPrices";
import { message } from "ant-design-vue";
import Pagination from "./Pagination.vue";
import interfacets from "@/api/open/privacy/interface";
import amountts from "@/api/open/privacy/amount";

export default {
  components: {
    "pagi-nation": Pagination,
  },
  props: {},
  data() {
    return {
      telTypeRadio: null,
      amount_id: [], // 拿到的通信账户类型
      searchForm: {
        amountId: "",
        type: "",
      },
      interfaceIdType: "", //接口类型

      interface_id: [], // 拿到的接口类型
      groupTotal: 0,
      groupPage: 1,
      groupPageSize: 10,
      moduleList: [],
      activeKey: "1",
      uid: "",
      interfaceList: [],
      billingType: [
        { value: 0, name: "月租" },
        { value: 1, name: "单计费" },
        { value: 2, name: "双计费" },
      ],
      privacylist: [
        {
          uid: "", //用户id
          interfaceId: "", //接口类型
          type: null, //价格类型
          price: "", //价格
          status: 0, //模块状态
        },
      ],
      doubleClick: false,
      otherList: {
        uid: "", //用户id
        usePrice: "", //用户使用价格
        apiPrice: "", //接口能力价格
        useStatus: 0, //开通状态
        apiStatus: 0, //能力状态
        asrPrice: "", //一句话价格
        asrStatus: 0, //一句话状态
      },
    };
  },
  computed: {},
  watch: {
    activeKey(news, old) {
      this.otherList.uid = localStorage.getItem("uid");
      let uid = localStorage.getItem("uid");
      if (news == "1") {
        this.getUserPriceQuery(uid);
      }
      if (news == "2") {
        this.getByUidVoice(uid);
      }
      if (news == "3") {
        this.getByUidBlack(uid);
      }
    },
  },
  mounted() {
    this.getApiType();
    this.getDataList();
    this.getInterfaceQuery();
    this.getDownLowData();
  },
  methods: {
    // 获取下拉数据
    getDownLowData() {
      // 获取通信账户类型
      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data;
      });
    },
    queryprivacy() {
      this.groupPage = 1;
      let uid = localStorage.getItem("uid");
      this.getUserPriceQuery(uid);
    },
    getApiType() {
      interfacets
        .getOption({
          uid: localStorage.getItem("uid"),
          optionKey: "black_resource",
        })
        .then((res) => {
          this.telTypeRadio = res.data.optionValue;
        });

      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data;
      });
    },
    handleGroupChange(params) {
      this.groupPageSize = params.limit;
      this.groupPage = params.page;

      let uid = localStorage.getItem("uid");
      this.getUserPriceQuery(uid);
    },

    // 获取用户id
    getUid(id) {
      if (id == undefined || id == null) {
        this.uid = localStorage.getItem("uid");
      } else {
        this.uid = id;
      }
      this.getUserPriceQuery(id);
    },
    //    //全部初始化
    //    init(){

    //    },
    // 获取隐私号用户价格
    getInterfaceQuery() {
      interfaces.interfaceQueryName({}).then((res) => {
        this.interfaceList = res.data;
      });
    },
    // 获取模块列表
    getDataList() {
      app.appList({}).then((res) => {
        let newModuleList = res.data.records;
        newModuleList.forEach((el, e) => {
          //去除系统模块
          if (el.id == 0) {
            newModuleList.splice(e, 1);
          }
        });
        this.moduleList = newModuleList;
      });
    },
    // 获取隐私号用户价格
    getUserPriceQuery(id) {
      userPrice
        .userPriceQueryNew({
          uid: id,
          page: this.groupPage,
          pageSize: this.groupPageSize,
          interfaceId: this.interfaceIdType,
          ...this.searchForm,
        })
        .then((res) => {
          if (res.data.length != 0) {
            this.privacylist = res.data.records;
            this.groupTotal = res.data.total;
          } else {
            this.privacylist = [
              {
                uid: localStorage.getItem("uid"),
                interfaceId: "",
                type: null,
                price: "",
                status: 0,
              },
            ];
          }
        });
    },
    // ===============隐私号
    // 添加一条
    addprivacy() {
      let newJson = {
        uid: this.uid, //用户id
        interfaceId: "", //接口类型
        type: null, //价格类型
        price: "", //价格
        status: 0, //模块状态
      };
      // this.newJson.uid = this.uid
      this.privacylist.push(newJson);
    },
    // 删除
    deleteOne(index, item) {
      this.$confirm("将删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (item.id == undefined) {
            this.privacylist.splice(index, 1);
          } else {
            userPrice
              .userPriceDelete({
                id: item.id,
              })
              .then((res) => {
                this.privacylist.splice(index, 1);
              });
          }
        })
        .catch(() => {
          // this.$notify.info({
          //     title: '通知',
          //     message: '已取消删除!'
          // });
        });
    },
    // 取消
    cancel() {
      this.getUserPriceQuery(this.uid);
    },
    // 保存
    save() {
      console.log();
      if (this.privacylist.length == 0) {
        this.$notify.info({
          title: "通知",
          message: "请设置配置项后再保存!",
        });
      } else {
        if (this.doubleClick == false) {
          this.doubleClick = true;
          // this.privacylist[0].uid = this.uid
          userModule
            .addUserModule({
              appId: this.activeKey,
              uid: this.uid,
              userPriceDtoList: this.privacylist,
            })
            .then((res) => {
              let uid = localStorage.getItem("uid");
              this.getUserPriceQuery(uid);
              this.$notify({
                title: "成功",
                message: "隐私号服务商设置完成！",
                type: "success",
              });
              this.doubleClick = false;
            })
            .catch((err) => {
              this.doubleClick = false;
            });
        }
      }
    },
    // ===========黑名单 or 语音质检
    getByUidBlack(id) {
      blackUserPrice
        .getByUid({
          uid: id,
        })
        .then((res) => {
          if (res.data != undefined) {
            this.otherList = res.data;
          } else {
            this.otherList = {
              uid: localStorage.getItem("uid"),
              usePrice: "",
              apiPrice: "",
              useStatus: 0,
              apiStatus: 0,
            };
          }
        });
    },
    getByUidVoice(id) {
      voiceUserPrices
        .getByUid({
          uid: id,
        })
        .then((res) => {
          if (res.data != undefined) {
            this.otherList = res.data;
          } else {
            this.otherList = {
              uid: localStorage.getItem("uid"),
              usePrice: "",
              apiPrice: "",
              useStatus: 0,
              apiStatus: 0,
            };
          }
        });
    },
    // 取消
    cancelOther(id) {
      let uid = localStorage.getItem("uid");
      if (this.activeKey == "2") {
        getByUidVoice(uid);
      }
      if (this.activeKey == "3") {
        getByUidBlack(uid);
      }
    },
    // 保存
    saveOther() {
      userModule
        .addUserModule({
          appId: this.activeKey,
          uid: this.uid,
          [this.activeKey == "2" ? "voiceUserPriceDto" : "blackUserPriceDto"]:
            this.otherList,
        })
        .then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
            return false;
          }

          if (this.activeKey == 3) {
            interfacets
              .setOption({
                uid: localStorage.getItem("uid"),
                optionKey: "black_resource",
                optionValue: this.telTypeRadio,
              })
              .then((res) => {});
          }

          this.$notify({
            title: "成功",
            message:
              this.activeKey == "2" ? "语音质检设置完成！" : "黑名单设置完成！",
            type: "success",
          });
          this.$emit("closeDialog", close);
        });
    },
  },
};
</script>

<style scoped>
/* .out-left{
        display: inline-block;
        margin-left: 1rem;
    } */
.input-box {
  display: inline-block;
  width: 10rem;
}
.input-box-other {
  width: 15rem;
}
.add-box {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}
.bottom-box {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
.bottom-box1 {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.pItem-box {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.privacy-most {
  height: calc(100vh - 500px);
  overflow-y: overlay;
}
.input-far-width {
  width: 390px;
}
.input-name-price-width {
  display: inline-block;
  width: 180px;
}
.input-price-width {
  width: 200px;
}
</style>
