// 用户管理
<template>
    <div class="jobs">
        <div class="screening">
            <el-dialog
                title="查询"
                v-model="theQueryDialog"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                width="600px"
            >
                <div class="">
                <el-form
                    label-position="left"
                    label-width="100px"
                    size="small"
                    :model="searchFrom"
                    ref="upform"
                >
                    <div class="p-0-30">
                        <el-form-item label="关键字">
                            <el-input class="input-searchFrom-width" v-model="searchFrom.keyword" placeholder="请输入需要查询的用户名称" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号">
                            <el-input class="input-searchFrom-width" v-model="searchFrom.phone" placeholder="请输入需要查询的手机号" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item label="企业ID">
                            <el-select
                            v-model="searchFrom.uid"
                            placeholder="请选择"
                            style="width: 100%"
                            clearable
                            filterable 
                            >
                            <el-option
                                v-for="(item, index) in user_list"
                                :key="index"
                                :label="item.nickname"
                                :value="item.uid"
                            >
                                <div class="flex-nowrap-space-between">
                                <div :class="item.status == 1?'no-red':''">
                                    <span>{{ item.nickname }}</span>
                                    <span class="ml-5">(Id:{{ item.uid }})</span>
                                </div>
                                <div v-if="item.status == 1" class="no-red">禁</div>
                                </div>
                            </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="创建时间">
                            <el-date-picker
                                v-model="valueTime"
                                type="datetimerange"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                clearable
                                value-format="YYYY-MM-DD HH:mm:ss"
                                size="mini"
                                :default-time="defaultTime">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="状态：" prop="">
                            <el-select
                            v-model="statusName"
                            placeholder="请选择"
                            style="width: 100%"
                            clearable
                            size="mini"
                            class="input-searchFrom-width"
                            >
                                <el-option
                                    v-for="(item, index) in [{
                                        type:'',
                                        name:'全部'
                                    }, {
                                        type:0,
                                        name:'启用'
                                    },{
                                        type:1,
                                        name:'禁用'
                                    }]"
                                    :key="index"
                                    :label="item.name"
                                    :value="item.type"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <el-form-item>
                        <div class="flex-nowrap-flex-end">
                            <el-button
                            class="cancel"
                            plain
                            @click="reset"
                            round
                            >
                            重置
                            </el-button>
                            <el-button
                            class="confirmAdd"
                            @click="getDataList(true)"
                            round
                            >
                            查询
                            </el-button>
                        </div>
                    </el-form-item>
                </el-form>
                </div>
            </el-dialog>
        </div>
        <el-row>
            <el-col :span="12">
                <div class="title-name">
                    用户列表
                </div>
            </el-col>
            <el-col :span="12">
                <div class="title-buttom">
                    <div class="ml-10">
                        <el-button icon="el-icon-search" class="button-el" size="medium" @click="theQueryDialog=true">查询</el-button>
                    </div>
                    <div class="ml-10">
                        <el-dropdown>
                            <el-button class="button-el" size="medium" @click="addUser">
                            <icon-font type="icon-tianjia1" class="button-icon-font"/>
                                添加用户
                            </el-button>
                        </el-dropdown>
                    </div>
                    <div class="ml-10">
                        <el-dropdown>
                        <el-button class="button-el" size="medium">
                            <icon-font type="icon-daochushuju" class="button-icon-font"/>
                            导出数据
                            <icon-font type="icon-copy-4-36" class="button-icon-font"/>
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                            <el-dropdown-item @click="exportTableAll">全部</el-dropdown-item>
                            <el-dropdown-item @click="exportTable(1)">选定</el-dropdown-item>
                            <el-dropdown-item @click="exportTable(0)">搜索结果</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                        </el-dropdown>
                    </div>
                    <div class="ml-10">
                        <el-tooltip content="刷新列表" placement="top" effect="light">
                        <div class="font-refresh-out flex-nowrap-center-center" @click="reset(1)">
                            <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh"/>
                            <i v-else class="el-icon-loading loding-color"></i>
                        </div>
                        </el-tooltip>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div>
                    <el-table
                    v-loading="loading"
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    max-height="680px"
                    size="mini"
                    :row-style="{height:'50px'}"
                    :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#e0e5ff',color:'#24252F'}"
                    @selection-change="handleSelectionChange">
                        <el-table-column
                        type="selection"
                        width="40">
                        </el-table-column>
                        <el-table-column
                        width="70"
                        label="头像">
                        <template #default="scope">
                            <img class="user-img" :src="scope.row.avatar == null||scope.row.avatar == ''?require(`@/assets/images/default.png`):scope.row.avatar" alt="">
                        </template>
                        </el-table-column>
                        <el-table-column
                        width="190"
                        fixed
                        label="企业名称">
                        <template #default="scope">{{ scope.row.company == null?'-': scope.row.company}}</template>
                        </el-table-column>
                        <el-table-column
                        width="80"
                        fixed
                        label="企业Id">
                        <template #default="scope">{{ scope.row.uid == null?'-': scope.row.uid}}</template>
                        </el-table-column>
                        <el-table-column
                        width="140"
                        label="手机号">
                        <template #default="scope">{{ scope.row.phone == null?'-': scope.row.phone}}</template>
                        </el-table-column>
                        <el-table-column
                        width="120"
                        label="昵称">
                        <template #default="scope">{{ scope.row.nickname == null?'-': scope.row.nickname}}</template>
                        </el-table-column>
                        <el-table-column
                        width="120"
                        label="是否认证">
                        <template #default="scope">
                            <el-button size="mini" :class="typeList(scope.row.certType)" @click="openAttestation(scope.row)">{{ scope.row.certTypeText == null?'-': scope.row.certTypeText}}</el-button>
                        </template>
                        </el-table-column>
                        <el-table-column
                        width="100"
                        label="信用额度">
                        <template #default="scope">￥{{ scope.row.quota == null?'-': scope.row.quota}}</template>
                        </el-table-column>
                        <el-table-column
                        width="100"
                        label="保证金">
                            <template #default="scope">￥{{ scope.row.bond  == null?'0': scope.row.bond }}</template>
                        </el-table-column>
                        <el-table-column
                        width="120"
                        label="账户余额">
                        <template #default="scope">
                            <div class="flex">
                                <p style="margin-top:1em">￥{{ scope.row.balance == null?'-': scope.row.balance}}</p>
                                <el-popover  :width="520" trigger="hover" @show="accountDetail(scope.row.uid)">
                                    <template #reference>
                                        <div class="flex-nowrap-center-center ml-10">
                                            <icon-font type="icon-chakan" class="img-icon"/>
                                        </div>
                                    </template>
                                    <div>余额使用情况</div>
                                    <el-table 
                                    max-height="300"
                                    :data="accountList"
                                    v-loading="accountLoad">
                                        <el-table-column
                                            width="100"
                                            property="tradeTypeText"
                                            label="交易类型"
                                        ></el-table-column>
                                        <el-table-column
                                            width="100"
                                            property="tradeChannelText"
                                            label="交易渠道"
                                        ></el-table-column>
                                        <el-table-column
                                            width="100"
                                            property="dealAmount"
                                            label="金额"
                                        ></el-table-column>
                                        <el-table-column
                                            width="160"
                                            property="tradeTime"
                                            label="交易时间"
                                        ></el-table-column>
                                    </el-table>
                                </el-popover>
                            </div>
                        </template>
                        </el-table-column>
                        <el-table-column
                        label="已开通套餐"
                        width="280">
                        <template #default="scope">
                            <div v-if="scope.row.userPackets == null">未购买套餐</div>
                            <div v-else>
                                <el-popover  :width="850" trigger="hover">
                                    <template #reference>
                                        <div class="flex-nowrap-flex-start">
                                            <div>{{scope.row.userPackets[0].packetName}}</div>
                                            <div class="flex-nowrap-center-center ml-10">
                                                <icon-font type="icon-chakan" class="img-icon"/>
                                            </div>
                                            <div class="font-color-blue">
                                                {{ scope.row.userPackets.length }}
                                            </div>
                                        </div>
                                    </template>
                                    <div>套餐使用信息</div>
                                    <el-table 
                                    max-height="300"
                                    :data="scope.row.userPackets">
                                        <el-table-column
                                            width="150"
                                            property="packetName"
                                            label="套餐名称"
                                        ></el-table-column>
                                        <el-table-column
                                            width="100"
                                            property="minutes"
                                            label="剩余分钟数"
                                        ></el-table-column>
                                        <el-table-column
                                            width="100"
                                            property="nums"
                                            label="剩余次数"
                                        ></el-table-column>
                                        <el-table-column
                                            width="100"
                                            property="price"
                                            label="消费金额"
                                        ></el-table-column>
                                        <el-table-column
                                            width="160"
                                            property="createTime"
                                            label="开通时间"
                                        ></el-table-column>
                                        <el-table-column
                                            width="160"
                                            property="expireTime"
                                            label="到期时间"
                                        ></el-table-column>
                                    </el-table>
                                </el-popover>
                            </div>
                        </template>
                        </el-table-column>
                        <el-table-column
                        width="160"
                        label="创建时间">
                            <template #default="scope">{{ scope.row.createTime == null?'-': scope.row.createTime}}</template>
                        </el-table-column>
                        <el-table-column
                        width="160"
                        label="最近登陆时间">
                            <template #default="scope">{{ scope.row.loginTime == null?'-': scope.row.loginTime}}</template>
                        </el-table-column>
                        <el-table-column
                        fixed="right"
                        width="80"
                        label="正常/封禁">
                        <template #default="scope">
                            <a-switch 
                                size="small" 
                                v-model:checked="scope.row.status" 
                                :checkedValue="0"
                                :unCheckedValue="1"
                                @click="onSwitch(scope.row, scope.row.status)" 
                            />
                        </template>
                        </el-table-column>
                        <el-table-column label="操作"
                        fixed="right"
                        width="520">
                            <template #default="scope">
                                <div class="flex-nowrap-space-around">
                                    <span class="button-font-color" @click="deleteUser(scope.row)">
                                        <icon-font type="icon-copy-4-03"/>
                                        删除
                                    </span>
                                    <span class="button-font-color" @click="modifyOther(scope.row)">
                                        <icon-font type="icon-koufei"/>
                                        扣费
                                    </span>
                                    <span class="button-font-color" @click="loginwebuser(scope.row)">
                                        <icon-font type="icon-qingqiuxiezhu-copy"/>
                                        协助
                                    </span>
                                    <span class="button-font-color" @click="modify(scope.row)">
                                        <icon-font type="icon-chongzhi"/>
                                        充值
                                    </span>
                                    <span class="button-font-color" @click="setPrice(scope.row)">
                                        <icon-font type="icon-web-icon-"/>
                                        设置单价
                                    </span>
                                    <span class="button-font-color" @click="setPassword(scope.row)">
                                        <icon-font type="icon-copy-4-161"/>
                                        设置密码
                                    </span>
                                    <span class="button-font-color" @click="modifyInformation(scope.row)">
                                        <icon-font type="icon-bianji"/>
                                        编辑信息
                                    </span>
                                    <span class="button-font-color" @click="showVos(scope.row)">
                                        <icon-font type="icon-jiekouguanli"/>
                                        VOS设置
                                    </span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <div class="pager">
            <div>
               <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                background
                :page-sizes="[20, 50, 100, 200]"
                :page-size="searchFrom.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 弹窗充值 -->
        <div>
            <el-form label-position="left" label-width="100px" :model="upform" :rules="rules" ref="upform">
                <el-dialog
                title="余额充值"
                v-model="centerDialogVisible"
                :destroy-on-close="true"
                :close-on-click-modal="false"
                width="30%"
                @close='closeDialog'>
                <div class="center-body">
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="收款方">
                                <div>
                                    {{ rowFrom.company }}
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="当前余额">
                                <div>
                                    ￥{{ rowFrom.balance }}
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="充值金额(元)" prop="amount">
                                <el-input v-model="upform.amount"  placeholder="请输入充值金额" oninput="value=value.replace(/[^0-9.]/g,'')" size="small">
                                    <template #append>￥</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="备注">
                                <el-input
                                    v-model="upform.remark"
                                    size="small"
                                    :autosize="{ minRows: 2, maxRows: 4 }"
                                    type="textarea"
                                    placeholder="请输入备注"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <template #footer>
                        <el-form-item>
                            <el-button class="cancel" plain @click="resetForm('upform')" size="medium">取消</el-button>
                            <el-button class="confirmAdd" @click="submitForm('upform')" size="medium">充值</el-button>
                        </el-form-item>
                </template>
                </el-dialog>
            </el-form>
        </div>
        <!-- 弹窗扣费 -->
        <div>
            <el-form label-position="left" label-width="100px" :model="decBalanceForm" :rules="rules" ref="DecBalanceForm">
                <el-dialog
                title="余额扣费"
                v-model="decBalanceVisible"
                :destroy-on-close="true"
                :close-on-click-modal="false"
                width="30%">
                <div class="center-body">
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="扣款方">
                                <div>
                                    {{ rowFrom.company }}
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="当前余额">
                                <div>
                                    ￥{{ rowFrom.balance }}
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="扣费金额" prop="amount">
                                <el-input v-model="decBalanceForm.amount"  placeholder="请输入金额" size="small" oninput="value=value.replace(/[^0-9.]/g,'')">
                                    <template #append>￥</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="备注">
                                <el-input
                                    v-model="decBalanceForm.remark"
                                    size="small"
                                    :autosize="{ minRows: 2, maxRows: 4 }"
                                    type="textarea"
                                    placeholder="请输入备注"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <template #footer>
                        <el-form-item>
                            <el-button class="cancel" plain @click="decresetForm('DecBalanceForm')" size="medium">取消</el-button>
                            <el-button class="confirmAdd" @click="decsubmitForm('DecBalanceForm')" size="medium">扣费</el-button>
                        </el-form-item>
                </template>
                </el-dialog>
            </el-form>
        </div>
        <!-- 弹窗应用信息设置 -->
        <div class="popup-view">
            <el-form label-position="top" label-width="100px" :model="ueserData" ref="setUpform">
                <el-dialog
                title="应用信息设置"
                v-model="setUpDialogVisible"
                :destroy-on-close="true"
                :close-on-click-modal="false"
                width="30%"
                @close='closeDialog'>
                <div class="center-body">
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="accessKey：">
                                <div>{{ueserData?.accessKey}}</div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="accessSerect：">
                               <div>{{ueserData?.accessSerect}}</div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="回调地址：">
                                <div class="fromData">
                                    <el-input v-model="ueserData.notifyUrl" placeholder="请输入回调地址"></el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="IP白名单：">
                                <div class="fromData">
                                    <el-input v-model="ueserData.whiteIp" placeholder="请输入IP白名单(多个以','分隔)"></el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <template #footer>
                        <el-form-item>
                            <el-button class="cancel" plain @click="setUpResetForm('setUpform')" round>取消</el-button>
                            <el-button class="confirmAdd" plain @click="setUpSubmitForm('setUpform')" round>保存</el-button>
                        </el-form-item>
                </template>
                </el-dialog>
            </el-form>
        </div>
        <!-- 弹窗新建用户 -->
        <div class="popup-view">
            <el-form label-position="left" label-width="100px" :model="addUserFrom" :rules="rules" ref="addUserfrom">
                <el-dialog
                title="新建用户"
                size="small"
                v-model="addDialogVisible"
                width="30%"
                :destroy-on-close="true"
                :close-on-click-modal="false"
                @close='closeAddDialog'>
                    <div class="p-0-20 form-out-box">
                        <el-form-item label="头像" prop="avatar">
                            <div class="flex-nowrap-flex-end">
                                <img-upload :width="60" :height="60" :type="'1'" :img="addUserFrom.avatar" @success="handleImgSuccess" @delete="handleImgDelete"></img-upload>
                            </div>
                        </el-form-item>
                        <el-form-item label="昵称" prop="nickname">
                            <div class="line-el-select">
                                <el-input v-model="addUserFrom.nickname" size="small" placeholder="请输入昵称"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="手机号" prop="phone">
                            <div class="line-el-select">
                                <el-input v-model="addUserFrom.phone" size="small" placeholder="请输入登录手机号"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="登录密码" prop="password">
                            <div class="line-el-select">
                                <el-input v-model="addUserFrom.password" size="small" placeholder="请设置密码" show-password></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="企业名称" prop="company">
                            <div class="line-el-select">
                                <el-input v-model="addUserFrom.company" size="small" placeholder="请输入企业名称"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="保证金----" prop="oldBond">
                            <div class="line-el-select">
                                <el-input-number v-model="addUserFrom.oldBond" size="small" :precision="0" :step="1" :min="0"/><span class="ml-10">千元</span>
                            </div>
                        </el-form-item>
                        <el-form-item label="信用额度" prop="quota">
                            <div class="line-el-select">
                                <!-- <el-input-number v-model="addUserFrom.quota" size="small" :min="0"/> -->
                                <el-input-number v-model="addUserFrom.quota" size="small" :min="0"/>
                                <!-- <span class="ml-5 default-color">注意:信用额度不得超过保证金额度。</span> -->
                            </div>
                        </el-form-item>
                        <el-form-item label="回调地址">
                            <div class="line-el-select">
                                <el-input v-model="addUserFrom.notifyUrl" size="small" placeholder="请输入回调地址"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="IP白名单">
                            <div class="line-el-select">
                                <el-input v-model="addUserFrom.whiteIp" size="small" placeholder="请输入IP白名单（多个以；分隔）"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="备注">
                            <div class="line-el-select">
                                <el-input
                                    v-model="addUserFrom.remark"
                                    maxlength="150"
                                    placeholder="请输入备注"
                                    show-word-limit
                                    type="textarea"
                                />
                            </div>
                        </el-form-item>
                    </div>
                    <el-form-item label="">
                        <div class="flex-nowrap-flex-end">
                            <el-button class="cancel" size="medium" @click="resetAddForm('addUserfrom')">取消</el-button>
                            <el-button class="confirmAdd" size="medium" @click="submitAddForm('addUserfrom')">新建</el-button>
                        </div>
                    </el-form-item>
                </el-dialog>
            </el-form>
        </div>
        <!-- 弹窗设置模块单价 -->
        <div>
            <el-dialog
            title="设置模块单价"
            v-model="setModuleVisible"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
                <modulePrice ref="modulePrices"></modulePrice>
            </el-dialog>
        </div>
        <div class="popup-view">
            <el-form label-position="left" label-width="100px" :rules="rules" :model="addUserFrom" ref="addUserfrom">
                <el-dialog
                title="修改用户信息"
                v-model="informationVisible"
                :destroy-on-close="true"
                :close-on-click-modal="false"
                width="30%"
                size="small"
                @close='closeDialog'>
                <div class="p-0-20 form-out-box">
                    <el-form-item label="头像" prop="avatar">
                        <div class="flex-nowrap-flex-end">
                            <img-upload :width="60" :height="60" :type="'1'" :img="addUserFrom.avatar" @success="handleImgSuccess" @delete="handleImgDelete"></img-upload>
                        </div>
                    </el-form-item>
                    <el-form-item label="昵称" prop="nickname">
                        <div class="line-el-select">
                            <el-input v-model="addUserFrom.nickname" size="small" placeholder="请输入昵称"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="手机号" prop="phone">
                        <div class="line-el-select">
                            <el-input v-model="addUserFrom.phone" size="small" placeholder="请输入登录手机号"></el-input>
                        </div>
                    </el-form-item>
                    <!-- <el-form-item label="登录密码" prop="password">
                        <div class="line-el-select">
                            <el-input v-model="addUserFrom.password" size="small" placeholder="请设置密码" show-password></el-input>
                        </div>
                    </el-form-item> -->
                    <el-form-item label="企业名称" prop="company">
                        <div class="line-el-select">
                            <el-input v-model="addUserFrom.company" size="small" placeholder="请输入企业名称"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="保证金" prop="oldBond">
                        <div class="line-el-select">
                            <el-input-number v-model="addUserFrom.oldBond" size="small" :precision="0" :step="1" :min="0"/><span class="ml-10">千元</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="信用额度" prop="quota">
                        <div class="line-el-select">
                            <!-- <el-input-number v-model="addUserFrom.quota" size="small" :min="0"/> -->
                            <el-input-number v-model="addUserFrom.quota" size="small" :min="0"/>
                            <!-- <span class="ml-5 default-color">注意:信用额度不得超过保证金额度。</span> -->
                        </div>
                    </el-form-item>
                    <el-form-item label="accessKey">
                        <div>{{ueserData?.accessKey}}</div>
                    </el-form-item>
                    <el-form-item label="accessSerect">
                        <div>{{ueserData?.accessSerect}}</div>
                    </el-form-item>
                    <el-form-item label="回调地址">
                        <div class="line-el-select">
                            <el-input v-model="addUserFrom.notifyUrl" size="small" placeholder="请输入回调地址"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="IP白名单">
                        <div class="line-el-select">
                            <el-input v-model="addUserFrom.whiteIp" size="small" placeholder="请输入IP白名单（多个以；分隔）"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="备注">
                        <div class="line-el-select">
                            <el-input
                                v-model="addUserFrom.remark"
                                maxlength="150"
                                placeholder="请输入备注"
                                show-word-limit
                                type="textarea"
                            />
                        </div>
                    </el-form-item>
                </div>
                <el-form-item label="">
                    <div class="flex-nowrap-flex-end">
                        <el-button class="cancel" size="medium" @click="editpResetForm('addUserfrom')">取消</el-button>
                        <el-button class="confirmAdd" size="medium" @click="editSubmitForm('addUserfrom')">修改</el-button>
                    </div>
                </el-form-item>
                </el-dialog>
            </el-form>
        </div>
        <div>
            <el-dialog
                title="认证详情"
                v-model="attestationVisible"
                width="50%"
                :close-on-click-modal="false"
                :destroy-on-close="true">
                <div class="center-body" v-if="attestationList.type == 0">
                    <el-descriptions :column="1" border>
                        <el-descriptions-item label="认证类型" width="60px" align="center">{{ '该用户暂未认证' }}</el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class="center-body" v-if="attestationList.type == 1">
                    <el-descriptions :column="1" border>
                        <el-descriptions-item label="认证类型" width="60px" align="center">{{ attestationList.type == 1?'个人':'企业' }}</el-descriptions-item>
                        <el-descriptions-item label="姓名" width="60px" align="center">{{ attestationList.name }}</el-descriptions-item>
                        <el-descriptions-item label="证件号" width="60px" align="center">{{ attestationList.data.certNo }}</el-descriptions-item>
                </el-descriptions>
                </div>
                <div class="center-body" v-if="attestationList.type == 2">
                    <el-descriptions :column="1" border>
                        <el-descriptions-item label="认证类型" width="60px" align="center">{{ attestationList.type == 1?'个人':'企业' }}</el-descriptions-item>
                        <el-descriptions-item label="公司名" width="60px" align="center">{{ attestationList.name }}</el-descriptions-item>
                        <el-descriptions-item label="法人" width="60px" align="center">{{ attestationList.data.person }}</el-descriptions-item>
                        <el-descriptions-item label="统一社会信用代码" width="60px" align="center">{{ attestationList.data.regNum }}</el-descriptions-item>
                        <el-descriptions-item label="营业执照" width="60px" align="center">
                            <!-- <img class="attestationImg" :src="attestationList.data.imgUrl" alt=""> -->
                            <div class="demo-image__preview">
                                <el-image
                                style="width: 100px; height: 100px"
                                :src="url"
                                :preview-src-list="srcList"
                                >
                                </el-image>
                            </div>
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
            </el-dialog>
        </div>
        <!-- vos设置 -->
        <div>
            <el-dialog
                title="VOS设置"
                v-model="showVosDialog"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                width="1000px"
            >
                <setVosList :uid='userId' :phone='phone' />
            </el-dialog>
        </div>
        <!-- 设置密码 -->
        <div>
            <el-dialog
                title="设置密码"
                v-model="showVosDialogPassword"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                width="400px"
            >
                <el-form label-position="top" label-width="100px" :rules="rules" :model="passwordFrom" ref="passwordFrom">
                    <el-form-item label="登录密码" prop="password">
                        <div class="line-el-select">
                            <el-input v-model="passwordFrom.password" size="small" placeholder="请设置密码" show-password></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="">
                        <div class="flex-nowrap-flex-end">
                            <el-button class="cancel" size="medium" @click="editPasswordReset('passwordFrom')">取消</el-button>
                            <el-button class="confirmAdd" size="medium" @click="editPasswordSubmit('passwordFrom')">修改</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import userApp from '@/api/web/userApp'
import user from '@/api/web/user'
import imgUpload from '@/components/imgUpLoad'
import { IconFont } from "@/utils/iconfont"
import apiInfo from '@/api/open/user/apiInfo'
import approve from '@/api/web/approve'
import modulePrice from '@/components/setModulePrice'
import setVosList from './setVosList'
import userts from "@/api/web/user";
import trade from '@/api/web/trade'
import { setToken } from '@/plugins/cookie'
import { ElMessageBox, ElMessage } from 'element-plus'
export default {
    components: {
        IconFont,
        imgUpload,
        modulePrice,
        setVosList
    },
    data () {
        const validatePrice = (rule,value,callback) =>{
            let reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
            if(!value){
                callback(new Error('金额不能为空'))
            }else if(!reg.test(value)){
                callback(new Error('请输入正确的格式：整数或保留两位小数'))
            }else if(value.length > 10){
                callback(new Error('最多可输入10个字符'))
            }else{
            callback();
            }
        };
        return {
            tableHeight:'',
            searchFrom:{
                page:1,
                pageSize:20,
                userType: 1,
                nickname:'',
                keyword:'',
                status:'',
                phone:'',
                createTimeBegin:'',
                createTimeEnd:'',
            },//搜索值
            user_list: [], // 用户的集合
            accountList:[],     // 资金明细数组
            accountLoad:false,  // 资金明细控制
            information:{//用户信息
                uid:'',
                nickname:'',
                // phone:'',
                quota:''
            },
            passwordFrom:{
                uid:'',
                password:''
            },
            attestationVisible:false,
            showVosDialogPassword:false,
            showPageDialog:true,
            attestationList:{},
            url:'',
            srcList:[],
            informationVisible:false,
            statusName:'',
            currentPage: 1,//分页
            total:0,
            multipleSelection: [],//选中集合
            valueTime:[],//选中时间段
            defaultTime: [
                new Date(2000, 1, 1, 12, 0, 0),
                new Date(2000, 2, 1, 8, 0, 0)
            ], //默认时间 '12:00:00', '08:00:00'
            activeNames: [],//折叠面板
            tableData: [],//数据列表
            loading: true,// loding 状态启用
            centerDialogVisible: false,//弹窗启用
            addDialogVisible: false,
            setModuleVisible: false,
            setUpDialogVisible: false,
            upform: {//充值
                uid:'',
                amount:''
            },
            decBalanceVisible:false,
            decBalanceForm:{
                uid:'',
                amount:'',
                remark:''
            },
            addUserFrom: {//新增用户
                nickname: '',
                password: '',
                phone: '',
                avatar: '',
                company: '',
                quota: 0,
                oldBond: 0,
                notifyUrl:'',
                whiteIp:''
            },
            rowFrom:{},
            moduleList:[],
            theQueryDialog:false,
            ueserData:{
                id:'',
                accessKey:'',
                accessSerect:'',
                notifyUrl:'',
                whiteIp:''
            },
            phone:null,
            allIds:[],
            userId:null,
            showVosDialog:false,
            rules: {
                amount: [
                    {required: true,  trigger: 'blur', validator:validatePrice}
                ],
                nickname: [
                    { required: true, message: '请输入昵称', trigger: 'blur' },
                    { min: 2, max: 10, message: '长度在 2-10 个字符', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { min: 11, max:11, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入用户密码', trigger: 'blur' },
                    { min: 6, max:15, message: '用户密码长度为6-15个字符', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { min: 11, max:11, message: '手机号长度不正确', trigger: 'blur' }
                ],
                company: [
                    { required: true, message: '请输入企业名称', trigger: 'blur' },
                    { min: 2, max: 30, message: '长度在 2-30 个字符', trigger: 'blur' }
                ],
                quota: [
                    { required: true, message: '信用额度不能为空', trigger: 'blur' },
                ],
                oldBond: [
                    { required: true, message: '信用额度不能为空', trigger: 'blur' },
                ],
            }
            
        }
    },
    created() {
        this.tableHeight = this.$store.state.tableHeight
    },
    watch: {

    },
    mounted() {
        this.getDataList(true)
        this.getUserList()
    },
    methods: {
        // 获取资金明细
        accountDetail(uid){
            this.accountList = [];
            this.accountLoad = true;
            trade.tradeList({page:1,pageSize:5,uid:uid}).then(res=>{
                this.accountList = res.data.records;
                this.accountLoad = false;
            })
        },
        openAttestation(row){
            this.attestationList.type = row.certType
            approve.user({
                uid:row.uid
            }).then((res) => {
                if( res.data != undefined ){
                    this.attestationList.name = res.data.name
                    if(this.attestationList.type == 1){
                        this.attestationList.data = JSON.parse(res.data.material)
                    }else{
                        this.attestationList.data = JSON.parse(JSON.parse(res.data.material).webMaterial)
                        this.url = JSON.parse(JSON.parse(res.data.material).webMaterial).imgUrl
                        this.srcList.push(this.url)
                    }
                }
                this.attestationVisible = true
            })
        },
        // 获取用户列表
        getUserList() {
            userts.queryList({}).then((res) => {
                this.user_list = res.data;
            });
        },
        typeList(type){
            let typeMap = {
                0:'unverified',
                1:'personal',
                2:'enterprise',
                null:''
            }
            return typeMap[type]
        },
        deleteUser(row){
            ElMessageBox.confirm(
                '将删除该用户，是否继续？',
                '提示',
                {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                }
            )
                .then(() => {
                    userApp.deleteUser({
                        uid:row.uid,
                    }).then((res) => {
                        this.$notify({
                            title: '成功',
                            message: '该用户删除成功！',
                            type: 'success'
                        });
                        this.getDataList(false)
                    })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '已取消操作！',
                    })
                    this.getDataList(false)
                })
        },
        // 展示vos设置弹窗
        showVos(row){
            this.userId = row.uid
            this.phone = row.phone
            this.showVosDialog = true
        },
        // 协助
        loginwebuser(row){
            user.loginwebuser({
                uid:row.uid
            }).then(res => {
                setToken(res.data)
                this.$store.dispatch('login', res.data)
                localStorage.setItem('type','1')
                this.$router.push({ path: '/dashboard' })
            })
        },
        // 重置
        reset(kind) {
            this.valueTime = []
            this.statusName = ''
            this.searchFrom = {
                page: this.currentPage,
                pageSize: 20, 
                userType: 1,
            }
            if(kind == 1){
                this.theQueryDialog = false
                this.getDataList(true)
            }
        },
        // 获取用户列表
        getDataList(type) {
            this.loading = type
            this.searchFrom.createTimeBegin = this.valueTime[0]
            this.searchFrom.createTimeEnd = this.valueTime[1]
            this.searchFrom.status = this.statusName
            userApp.userList({
                ...this.searchFrom
            }).then((res) => {
                this.tableData = res.data.records
                this.total = res.data.total
                this.loading = false
            })
        },
        // 修改用户信息
        modifyInformation(row){
            user.getData({
                id:row.uid
            }).then((res) => {
                this.addUserFrom = res.data
                this.getLoginUserData(row.uid)
                this.informationVisible = true
                this.addUserFrom.oldBond = parseInt(parseInt(res.data.bond == null?0:res.data.bond)/1000)
            })
        },
        // 修改密码
        setPassword(row){
            this.passwordFrom.uid = row.uid
            this.showVosDialogPassword = true
        },
        editPasswordSubmit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    user.resetPassword({
                        ...this.passwordFrom
                    }).then((res) => {
                        this.$notify({
                            title: '成功',
                            message: '修改成功！',
                            type: 'success'
                        });
                        this.showVosDialogPassword = false
                    })
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        editPasswordReset(formName){
            this.$refs[formName].resetFields();
            this.showVosDialogPassword = false
        },
        editSubmitForm(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.addUserFrom.oldBond == null?this.addUserFrom.bond = 0:this.addUserFrom.bond = parseInt(this.addUserFrom.oldBond)*1000
                    user.editData({
                        ...this.addUserFrom
                    }).then((res) => {
                        this.$notify({
                            title: '成功',
                            message: '修改成功！',
                            type: 'success'
                        });
                        this.getDataList(false)
                        this.informationVisible = false
                    })
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        editpResetForm(formName){
            this.$refs[formName].resetFields();
            this.informationVisible = false
        },
        // 分页
        handleSizeChange(val) {
            this.searchFrom.pageSize = val
            this.getDataList(true)
        },
        handleCurrentChange(val) {
            this.searchFrom.page = val
            this.currentPage = val
            this.getDataList(true)
        },
        // 多选
        handleSelectionChange(val) {
            let newIds = [];
            val.forEach((item) => {
                newIds.push(item.uid);
            });
            this.multipleSelection = val;
            this.allIds = newIds;
        },
        // 批量删除
        deleteData() {
            console.log(this.multipleSelection)
        },
        // 添加用户
        addUser(){
            this.addUserFrom = {//新增用户
                nickname: '',
                password: '',
                phone: '',
                avatar: '',
                company: '',
                quota: 0,
                oldBond: 0,
                notifyUrl:'',
                whiteIp:''
            }
            this.addDialogVisible = true
        },
        // 头像上传
        handleImgSuccess(val) {
            this.addUserFrom.avatar = val
        },
        handleImgDelete() {
            this.addUserFrom.avatar = null
        },
        // 设置
        setUp(row){
            this.ueserData.id = row.uid
            this.getLoginUserData(row.uid)
            this.setUpDialogVisible = true
        },
        getLoginUserData(uid){
            apiInfo.addUserDataQuery({
                uid:uid
            }).then((res) => {
                this.ueserData = res.data
                this.addUserFrom.notifyUrl = res.data.notifyUrl
                this.addUserFrom.whiteIp = res.data.whiteIp
            })
        },
        // 应用信息设置
        setUpSubmitForm(){
            apiInfo.editUserData({
                id:this.ueserData.id,
                notifyUrl:this.ueserData.notifyUrl,
                whiteIp:this.ueserData.whiteIp
            }).then((res) => {
                this.setUpDialogVisible = false
                this.$notify({
                    title: '修改成功',
                    message: '配置已修改',
                    type: 'success'
                });
            })
        },
        setUpResetForm(formName){
            this.$refs[formName].resetFields();
            this.setUpDialogVisible = false
        },
        // 添加用户表单提交
        submitAddForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                this.addUserFrom.oldBond == null?this.addUserFrom.bond = 0:this.addUserFrom.bond = parseInt(this.addUserFrom.oldBond)*1000
                userApp.addUser({
                    ...this.addUserFrom
                }).then((res) => {
                    this.$notify({
                        title: '成功',
                        message: '添加成功！',
                        type: 'success'
                    });
                    this.getDataList(false)
                    this.addDialogVisible = false
                })
            } else {
                // console.log('error submit!!');
                return false;
            }
            });
        },
        resetAddForm(formName) {
            this.$refs[formName].resetFields();
            this.addDialogVisible = false
            this.centerDialogVisible = false
        },
        closeAddDialog() {
            this.$refs.addUserfrom.resetFields()
            this.centerDialogVisible = false
        },
        // 控制状态
        onSwitch(row, status){
            ElMessageBox.confirm(
                status == 0 ? '将启用该用户，是否继续？':'将禁用该用户，是否继续？',
                '提示',
                {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                }
            )
                .then(() => {
                    if(status == 0){
                        userApp.enableUser({
                            ids:[row.uid],
                        }).then((res) => {
                            this.$notify({
                                title: '成功',
                                message: status == 0 ? '该用户启用成功！' : '该用户禁用成功！',
                                type: 'success'
                            });
                            this.getDataList(false)
                        })
                    }else{
                        userApp.disableUser({
                            ids:[row.uid],
                        }).then((res) => {
                            this.$notify({
                                title: '成功',
                                message: status == 0 ? '该用户启用成功！' : '该用户禁用成功！',
                                type: 'success'
                            });
                            this.getDataList(false)
                        })
                    }
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '已取消操作！',
                    })
                    this.getDataList(false)
                })
            
        },
        modifyOther(row) {
            this.rowFrom = row
            this.decBalanceForm.uid = row.uid
            this.decBalanceVisible = true
        },
        // 修改
        modify(row) {
            this.rowFrom = row
            this.upform.uid = row.uid
            this.centerDialogVisible = true
        },
        // 取消
        cancelEvent() {
            console.log("cancel!");
        },
        // 设置模块单价
        setPrice(row){
            this.setModuleVisible = true
            localStorage.setItem('uid',row.uid)
            setTimeout(() => {
                this.$refs.modulePrices.getUid(row.uid)
            }, 100);
        },
        // closeAddPrice(){
        //     this.$refs.modulePrices.getUid(row.uid)
        // },
        // 导出用户列表(全部)
        exportTableAll(){
            // this.$confirm('将导出全部数据, 是否继续?', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }).then(() => {
            //     alert(1)
            //     userApp.exportList({
            //         ...this.searchFrom
            //     }).then((res) => {
            //         this.$notify({
            //             title: '成功',
            //             message: '列表导出导出任务已建立,稍后请在消息模块下载',
            //             type: 'success'
            //         });
            //         this.getDataList(true)
            //     })
            // }).catch(() => {
            //     this.$notify.info({
            //         title: '通知',
            //         message: '已取消删除!'
            //     });
            // });
            ElMessageBox.confirm(
                '将导出全部数据, 是否继续?',
                '提示',
                {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                }
            )
                .then(() => {
                    userApp.exportList({userType: 1}).then((res) => {
                        ElMessage({
                            type: 'success',
                            message: '列表导出导出任务已建立,稍后请在消息模块下载',
                        })
                    })
                
                })
                .catch(() => {
                ElMessage({
                    type: 'info',
                    message: 'Delete canceled',
                })
                })
        },
        // 导出用户列表(条件，筛选)
        exportTable(type){
            this.$confirm('将按照条件导出数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if(type == 0){
                    userApp.exportList({
                        ...this.searchFrom
                    }).then((res) => {
                        this.$notify({
                            title: '成功',
                            message: '列表导出导出任务已建立,稍后请在消息模块下载。',
                            type: 'success'
                        });
                    })
                }else{
                    if(this.allIds.length == 0){
                        ElMessage({
                            message: '请勾选需要导出的条目！',
                            type: 'warning',
                        })
                    }else{
                        userApp.exportList({
                            ids:this.allIds,
                            userType: 1
                        }).then((res) => {
                            this.$notify({
                                title: '成功',
                                message: '列表导出导出任务已建立,稍后请在消息模块下载',
                                type: 'success'
                            });
                        })
                    }
                }
            }).catch(() => {
                this.$notify.info({
                    title: '通知',
                    message: '已取消删除!'
                });
            });
        },
        // 扣费
        decsubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                userApp.decBalance({
                    ...this.decBalanceForm
                }).then((res) => {
                    this.$notify({
                        title: '成功',
                        message: '扣费成功！',
                        type: 'success'
                    });
                    this.getDataList(false)
                    this.decBalanceVisible = false
                })
            } else {
                // console.log('error submit!!');
                return false;
            }
            });
        },
        decresetForm(formName) {
            this.$refs[formName].resetFields();
            this.decBalanceVisible = false
        },
        // 表单提交
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                userApp.addBalance({
                    ...this.upform
                }).then((res) => {
                    this.$notify({
                        title: '成功',
                        message: '充值成功！',
                        type: 'success'
                    });
                    this.getDataList(false)
                    this.centerDialogVisible = false
                })
            } else {
                // console.log('error submit!!');
                return false;
            }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.centerDialogVisible = false
        },
        closeDialog() {
            this.$refs.upform.resetFields()
            this.value = []
            this.centerDialogVisible = false
        },
    }
}
</script>

<style lang="scss" scoped>
.pager {
    display: flex;
    justify-content: flex-end;
}
.jobs{
    height: 100%;
    overflow-y: overlay;
    padding-right: 18px;
    .title-name{
        font-size: .9375rem;
        font-weight: 600;
        color: #303133;
    }
    .top-first{
        .search-box{
            width: 18.75rem;
        }
    }
}
.form-out-box{
  height: 600px;
  margin-bottom: 20px;
  overflow-y: overlay;
}
.screening{
    margin-bottom: .9375rem;
}
.el-row {
    margin-bottom: .9375rem;
    &:last-child {
        margin-bottom: 0;
    }
}
.space-tile{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.title-buttom{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.editor{
    color: #0170ED;
    background: #E3F0FF;
}
.center-body{
    margin: 0 1.875rem;
}
.deletetable{
    color: #FF845B;
    background: #FFE4DB;
}
.deletetable-other{
    margin-right: 1.25rem;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
.cancel{
    color: #637DFF;
    border: #637DFF solid 1px;
}
.screening-out{
    margin: 0 .9375rem;
}
.img-icon{
    cursor: pointer;
    color: #7594FF; 
    // font-size: 1rem;
}
.showIcon{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    overflow-y: overlay;
    height: 28.125rem;
    .out-icon{
        border-radius: .5rem;
        .icon-box{
            display: flex;
            justify-content: space-between;
            align-items: center;
            justify-content: center;
            width:  4rem;
            height: 3rem;
        }
        .icon-name{
            text-align: center;
            width: 4rem;
            height: 2.5rem;
            line-height: 2.5rem;
            
        }
    }
    
}
.out-icon:hover{
    background: #E3F0FF;
    color: #0170ED;
}
.user-img{
    border-radius: 50%;
    border: solid .125rem #707070;
    width: 2.4125rem;
    height: 2.4125rem;
}
.unverified{
    color: #F56C6C;
}
.personal{
    color: #67C23A;
}
.enterprise{
    color: #409EFF;
}
.attestationImg{
    transform: rotate(-90deg);
    width: 5rem;
    height: 5rem;
}
</style>

<style lang="scss">
.jobs .el-collapse-item__content {
    padding-bottom: 0 !important;
}
// .jobs .el-button {
//     // border-color: #FFFFFF !important;
// }
</style>